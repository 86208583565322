import { Routes } from '@angular/router';

export const routes: Routes = [
    { path: '', loadComponent: () => import('./features/service-management/taas-service-manager-dashboard.component').then(module => module.TaasServiceManagerDashboardComponent) },
    { path: 'users', loadComponent: () => import('./features/user-management/pages/user-list/taas-user-management.component').then(module => module.TaasUserManagementComponent) },
    { path: 'docs/public', loadComponent: () => import('./features/document-management/taas-document-management.component').then(module => module.TaasDocumentManagementComponent) },
    { path: 'docs/private', loadComponent: () => import('./features/document-management/taas-document-management.component').then(module => module.TaasDocumentManagementComponent) },
    { path: 'support', loadComponent: () => import('./features/support/pages/overview/taas-support-overview.component').then(module => module.TaasSupportOverviewComponent) },
    { path: 'legal/public', loadComponent: () => import('./features/legal/taas-legal.component').then(module => module.TaasLegalComponent) },
    { path: 'legal/private', loadComponent: () => import('./features/legal/taas-legal.component').then(module => module.TaasLegalComponent) },
    { path: 'support/knowledgebase', loadComponent: () => import('./features/support/pages/knowledge-base/taas-support-knowledgebase.component').then(module => module.TaasSupportKnowledgebaseComponent) },
    { path: 'support/tickets', loadComponent: () => import('./features/support/pages/support-tickets/taas-support-tickets.component').then(module => module.TaasSupportTicketsComponent) }
];
