/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

(async () => {
  const app = await bootstrapApplication(AppComponent, appConfig);
  if(app.isStable){
    console.debug("Application bootstraping complete");
  }
})();