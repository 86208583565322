import { Component, inject, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';

import { TaasUserService } from './core/services/taas-user-service.service';
import { TaasServiceDataProviderService } from './core/services/taas-service-data-provider.service';
import { TAAS_MENU_ITEM_LIST_SERVICEMANAGER } from './taas-menuitems-portal';
import { environment } from '../environments/environment';

import { TaasMainLayoutComponent, TaasMenuItem, TaasUserDetails, TaasHiddenVersionInfoComponent } from 'utimaco-common-ui-angular';

//NOTE: to be able to import json like this, make sure to add these settings to your ts config:
// "resolveJsonModule": true,
// "allowSyntheticDefaultImports": true,
import appPackageJson from '../../package.json';
import commonUiPackageJson from 'utimaco-common-ui-angular/package.json';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TaasMainLayoutComponent, TaasHiddenVersionInfoComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent implements OnInit {
  public taasServiceDataProviderService = inject(TaasServiceDataProviderService);

  private router = inject(Router);
  private sanitizer = inject(DomSanitizer);
  private taasUserService = inject(TaasUserService);

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.url;
      }
    });

    this.taasUserService.getCurrentUserDetails().then((taasUserDetails) => {
      this.currentUser = taasUserDetails;
    });
  }

  currentUser: TaasUserDetails = {
    givenName: "",
    familyName: "",
    email: "",
    initials: ""
  };

  activeRoute: string = "/";
  taasMenuItemsServiceManager: TaasMenuItem[] = TAAS_MENU_ITEM_LIST_SERVICEMANAGER;
  commonUiLibVersion: string = commonUiPackageJson.version;
  appVersion: string = appPackageJson.version;

  public get pageTitle() {
    return this.sanitizer.bypassSecurityTrustHtml('<b>Trust as a Service</b> Marketplace');
  }

  handleRouteUpdateRequest(path: string) {
    this.router.navigateByUrl(path);
  }

  navigateHome() {
    this.router.navigateByUrl('/');
  }

  performLogout() {
    this.taasUserService.performLogout();
  }

  handleUserContextMenuEvent(event: string) {
    switch (event) {
      case "logout":
        this.performLogout();
        break;
      case "showOwnProfile": {
        const profileUrl = environment.keycloakBaseUrl + "/realms/" + environment.keycloakRealm + "/account/";
        window.open(profileUrl, "_blank");
        break;
      }
      default:
        console.log("Unsupported user info context event received: ", event);
        break;
    }
  }
}
