{
  "name": "utimaco-common-ui-angular",
  "version": "0.11.7",
  "author": "Utimaco IS GmbH",
  "description": "A shared library containing several Angular components that are used in the TaaS / Utimaco Portal Layout to ease bootrapping a new UI and to have a central place for managing reuseable UI components and services",
  "repository": {
    "type": "git",
    "url": "https://git.fra1.us.utimaco.cloud/taas/taas-core-services/utimaco-common-ui-library"
  },
  "publishConfig": {
    "registry": "https://repo.fra1.us.utimaco.cloud/repository/npm-hosted/"
  },
  "peerDependencies": {
    "@angular/cdk": "^17.3.5",
    "@angular/common": "^17.3.5",
    "@angular/core": "^17.3.5",
    "@angular/platform-browser": "^17.3.0"
  },
  "dependencies": {
    "tslib": "^2.3.0"
  },
  "sideEffects": false,
  "module": "fesm2022/utimaco-common-ui-angular.mjs",
  "typings": "index.d.ts",
  "exports": {
    "./package.json": {
      "default": "./package.json"
    },
    ".": {
      "types": "./index.d.ts",
      "esm2022": "./esm2022/utimaco-common-ui-angular.mjs",
      "esm": "./esm2022/utimaco-common-ui-angular.mjs",
      "default": "./fesm2022/utimaco-common-ui-angular.mjs"
    }
  }
}